<template>
  <v-sheet>
    <v-app-bar>
      <v-toolbar-title>{{ config.product.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" :to="{ name:'auth.signin' }">Login</v-btn>
    </v-app-bar>
    <v-container class="py-8">
      <v-row>
        <v-col>
          <h1 class="text-h1">Terms and conditions</h1>
          <div class="text-h6 text-lg-h5 mt-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus impedit error labore doloremque fugit! Dolor fugit molestiae vero quos quisquam nobis, eos debitis magni omnis ea incidunt amet voluptate dignissimos!</div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import config from '../../configs'

export default {
  name: 'TermsOfServicePage',
  data() {
    return {
      config
    }
  }
}
</script>

<style scoped>

</style>
